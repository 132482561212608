import {AuthenticationResult, PublicClientApplication} from '@azure/msal-browser';
import {AuthConfig, createMsalConfig} from './createMsalConfig';
import {envVars} from './envVars';
import {AuthSettings} from '@types';

const config: AuthConfig = {
  clientId: envVars.MSAL_CLIENTID,
  authority: envVars.MSAL_AUTHORITY,
  knownAuthorities: [envVars.MSAL_AUTHORITYDOMAIN],
  navigateToLoginRequestUrl: false,
};

const scope = [envVars.MSAL_RESOURCES_SCOPE];
const msalInstance = new PublicClientApplication(createMsalConfig(config));

const getAccessToken = (): Promise<AuthenticationResult> =>
  msalInstance.acquireTokenSilent({
    scopes: scope,
    account: msalInstance.getAllAccounts()[0],
  });

export const b2cSettings: AuthSettings = {msalInstance, scope, getAccessToken};
