/* eslint-disable no-restricted-imports */
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

/**
 * Creates an axios instance initialized with the given API base URL and authentication headers.
 * Extend and add to this method if you want to modify how API clients are made.
 *
 * @param baseURL the domain name and prefix endpoint for all subsequent methods using this client
 * @param headers the headers to add to requests with this client
 * @returns an axios client to use with headers and base URL set
 */
export const createClient = (clientConfig: AxiosRequestConfig = {}): AxiosInstance => {
  const client = axios.create(clientConfig);
  /**
   * Initialization for this API's HTTP client.
   *
   * Add authentication token parsing here.
   */
  client.interceptors.request.use(
    config =>
      // Do something before request is sent
      config,
    error =>
      // Do something with request error
      Promise.reject(error),
  );
  client.interceptors.response.use(
    response =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      response,
    error =>
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      Promise.reject(error),
  );
  return client;
};

/**
 * Example using using {@link createClient} for a commonly re-used endpoint
 */
export const createExampleClient = (endpoint: `/${string}`): AxiosInstance =>
  createClient({baseURL: `http://localhost:8080/api/${endpoint}`});
