import {envVars} from './envVars';
import {Brand} from '@types';

export const brands: {[name: string]: Brand} = {
  surebridge: {
    logo: envVars.BASE_CDN_URI + '/brands/uhone/images/uhc_clico_logo.png',
    name: 'surebridge',
    displayName: 'The Chesapeake Life Insurance Company',
    hostName: 'surebridgeinsurance',
    favicon: envVars.BASE_CDN_URI + '/brands/surebridge/images/surebridge_favicon.ico',
  },
  healthmarkets: {
    logo: envVars.BASE_CDN_URI + '/brands/healthmarkets/images/healthmarkets_logo.svg',
    name: 'healthmarkets',
    displayName: 'HeathMarkets',
    hostName: 'healthmarkets',
    favicon: envVars.BASE_CDN_URI + '/brands/healthmarkets/images/healthMarkets_favicon.ico',
  },
  united: {
    logo: envVars.BASE_CDN_URI + '/brands/uhone/images/uhc_logo.svg',
    name: 'united',
    displayName: 'United',
    hostName: 'uhone',
    favicon: envVars.BASE_CDN_URI + '/brands/uhone/images/uhc_favicon.ico',
  },
};
