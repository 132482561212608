import log from 'loglevel';
import {envVars} from '../envVars';
import {createClient} from './clients';
import {prepareApiObject} from './http';

const client = createClient();

/**
 * HTTP API methods for this sampleApi
 */
const sampleApi = {
  sendLog: (message: string, level: log.LogLevel[keyof log.LogLevel]) =>
    client.post(envVars.LOG_LEVEL_ENDPOINT, {message, level}),
  testError: () => client.get<unknown>('https://httpstat.us/500'),
} as const;

prepareApiObject(sampleApi, ['sendLog']);

export {sampleApi};
