/* eslint-disable sonarjs/no-duplicate-string */
import {Typography} from '@uhc-tempo/components';
import {font} from '@uhc-tempo/components/tokens/token_modules/fonts.common';
import {Element, HTMLReactParserOptions, domToReact} from 'html-react-parser';

export const htmlParseOptions: HTMLReactParserOptions = {
  replace: domNode => {
    if (domNode instanceof Element) {
      switch (domNode.name) {
        case 'h1':
          return (
            <p
              style={{
                marginLeft: -37,
                fontFamily: font.family.sans.bold.value,
                fontSize: font.size.heading.one.value,
                lineHeight: font['line-height'].heading.one.value,
                color: font.color.heading.dark.value,
              }}
            >
              {domToReact(domNode.children, htmlParseOptions)}
            </p>
          );
        case 'h2':
          return (
            <p
              style={{
                marginLeft: -37,
                fontFamily: font.family.sans.bold.value,
                fontSize: font.size.heading.two.value,
                lineHeight: font['line-height'].heading.two.value,
                color: font.color.heading.dark.value,
              }}
            >
              {domToReact(domNode.children, htmlParseOptions)}
            </p>
          );
        case 'h3':
          return (
            <p
              style={{
                marginLeft: -37,
                fontFamily: font.family.sans.bold.value,
                fontSize: font.size.heading.three.value,
                lineHeight: font['line-height'].heading.three.value,
                color: font.color.heading.dark.value,
              }}
            >
              {domToReact(domNode.children, htmlParseOptions)}
            </p>
          );
        case 'h4':
          return (
            <p
              style={{
                marginLeft: -37,
                fontFamily: font.family.sans.bold.value,
                fontSize: font.size.heading.four.value,
                lineHeight: font['line-height'].heading.four.value,
                color: font.color.heading.dark.value,
              }}
            >
              {domToReact(domNode.children, htmlParseOptions)}
            </p>
          );
        case 'h5':
          return (
            <p
              style={{
                marginLeft: -37,
                fontFamily: font.family.sans.bold.value,
                fontSize: font.size.heading.five.value,
                lineHeight: font['line-height'].heading.five.value,
                color: font.color.heading.dark.value,
              }}
            >
              {domToReact(domNode.children, htmlParseOptions)}
            </p>
          );
        case 'h6':
          return (
            <p
              style={{
                marginLeft: -37,
                fontFamily: font.family.sans.bold.value,
                fontSize: font.size.heading.six.value,
                lineHeight: font['line-height'].heading.six.value,
                color: font.color.heading.dark.value,
              }}
            >
              {domToReact(domNode.children, htmlParseOptions)}
            </p>
          );
        case 'p':
          return (
            <Typography.Paragraph style={{margin: 0}}>
              {domToReact(domNode.children, htmlParseOptions)}
            </Typography.Paragraph>
          );
        default:
          return null;
      }
    }
  },
};
