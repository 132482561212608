import {envVars} from './envVars';

/**
 * Is the environment the local development environment?
 */
export const isLocalDevelopment = envVars.NODE_ENV === 'development';

/**
 * Generates a hash value for a given string.
 *
 * @param str the string to hash
 * @returns a hash value. collision is unlikely, but possible
 */
export function hash(str: string): number {
  return str
    .split('')
    .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
}

/**
 * Used to typed a function child component
 */
type FaaCFunction = <T>(args: T) => React.ReactNode;

/**
 * Returns the children or a invokes it if it a function child passing T.
 *
 * Adapted from: https://github.com/AzureAD/microsoft-authentication-library-for-js
 * /blob/427c2ada4b49114d962d33497795abd280ff6143/lib/msal-react/src/utils/utilities.ts#L10
 *
 * @param children
 * @param args
 */
export function getChildrenOrFunction<T>(
  children: React.ReactNode | FaaCFunction,
  args: T,
): React.ReactNode {
  if (typeof children === 'function') {
    return children(args);
  }
  return children;
}

/**
 * Reviver for JSON.parse.
 * Adapted from https://stackoverflow.com/questions/13557064/formatting-json-data-to-be-camelcased
 *
 * @param k
 * @param value
 */
export function toCamelCase(k: unknown, value: {[x: string]: unknown}) {
  if (value && typeof value === 'object') {
    for (const v in value) {
      if (/^[A-Z]/.test(v) && Object.hasOwnProperty.call(value, v)) {
        value[v.charAt(0).toLowerCase() + v.substring(1)] = value[v];
        delete value[v];
      }
    }
  }
  return value;
}
