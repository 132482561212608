import {SignaturesApiClient} from '@optum-uhone-sales/signatures-api-client/Signatures';
import {InternalAxiosRequestConfig} from 'axios';
import {getAccessToken} from '../auth';
import {envVars} from '../envVars';
import {createClient} from './clients';

const client = createClient();

const makeInterceptor = (
  interceptorCallback: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig,
) =>
  client.interceptors.request.use(interceptorCallback, error =>
    // Do something with request error
    Promise.reject(error),
  );
const ejectInterceptor = (interceptor: number) => client.interceptors.request.eject(interceptor);

/**
 * Initialization for this API's HTTP client.
 *
 * Add authentication token parsing here.
 */
client.interceptors.request.use(
  async config => {
    if (config && config.headers) {
      const result = await getAccessToken();

      // Do something before request is sent
      config.headers.Authorization = `Bearer ${result.accessToken || ''}`;
    }
    return config;
  },
  error =>
    // Do something with request error
    Promise.reject(error),
);

client.interceptors.response.use(
  response =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  error =>
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    Promise.reject(error),
);

const signatureApi = new SignaturesApiClient(`${envVars.BASE_API_URI}`, client);

export {signatureApi, makeInterceptor, ejectInterceptor};
