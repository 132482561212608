import './styles.css';

export type LoadingSpinnerProps = {
  margin?: 'unset' | 'auto';
};

/**
 * Loading spinner component that rotates color around a circle to indicate loading.
 *
 * INTENTIONALLY USING CSS-MODULE. (because JSS may not be loaded yet)
 */
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({margin = 'auto'}) => (
  <div style={{height: '100vh', display: 'flex'}}>
    <div className='loader' style={{margin: margin}} />
  </div>
);
