import '@uhc-tempo/components/tempo.css';
import './index.css';
import {StrictMode, Suspense, lazy} from 'react';
import {render} from 'react-dom';
import {LoadingSpinner} from './components/LoadingSpinner';
import {initialize, log, reportWebVitals} from '@utils';

const App = lazy(() => import('./pages/App'));

initialize().then(() => {
  /**
   * This is the main entry point for the app.
   */
  render(
    <StrictMode>
      <Suspense fallback={<LoadingSpinner />}>
        <App />
      </Suspense>
    </StrictMode>,
    document.querySelector('#root'),
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(log.debug);
});
