import {LogLevel} from '@azure/msal-browser';
import log from 'loglevel';

export type AuthConfig = {
  clientId: string;
  authority: string;
  knownAuthorities: string[];
  navigateToLoginRequestUrl: boolean;
};

export function createMsalConfig(authConfig: AuthConfig) {
  return {
    auth: authConfig,
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
      //Fixes an issue where some broswers clear session storage upon redirect and auth fails
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: unknown, message: unknown, containsPii: unknown) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              log.error(message);
              return;
            case LogLevel.Info:
              log.info(message);
              return;
            case LogLevel.Verbose:
              log.debug(message);
              return;
            case LogLevel.Warning:
              log.warn(message);
              return;
          }
        },
      },
    },
  };
}
