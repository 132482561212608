import {
  AcknowledgmentSection,
  Document,
  SaveAcknowledgmentDto,
  SaveRecipientSignatureCommand,
} from '@optum-uhone-sales/signatures-api-client/Signatures';

export const makeRecipientSignatureBody = (
  documents: Document[],
  acknowledgmentSections: AcknowledgmentSection[],
) => {
  const body = new SaveRecipientSignatureCommand();
  const acknowledgments: {
    [key: number]: SaveAcknowledgmentDto;
  } = {};

  acknowledgmentSections?.forEach(s => {
    s.acknowledgments
      ?.filter(a => !a.isReadOnly)
      .forEach(a => {
        const saveAcknowledgmentDto = new SaveAcknowledgmentDto();
        saveAcknowledgmentDto.hasAcknowledged = true;
        acknowledgments[a.id] = saveAcknowledgmentDto;
      });
  });

  body.acknowledgments = acknowledgments;

  return body;
};
