/* eslint-disable sonarjs/no-duplicate-string */
import {Acknowledgment} from '@optum-uhone-sales/signatures-api-client/Signatures';
import {Typography} from '@uhc-tempo/components';
import {font} from '@uhc-tempo/components/tokens/token_modules/fonts.common';
import ReactMarkdown from 'react-markdown';

export const markdownLabel = (acknowledgment: Acknowledgment) =>
  (
    <ReactMarkdown
      children={acknowledgment.text ? acknowledgment.text : ''}
      components={{
        h1: ({children}) => (
          <p
            children={children}
            style={{
              marginLeft: -37,
              fontFamily: font.family.sans.bold.value,
              fontSize: font.size.heading.one.value,
              lineHeight: font['line-height'].heading.one.value,
              color: font.color.heading.dark.value,
            }}
          />
        ),
        h2: ({children}) => (
          <p
            children={children}
            style={{
              marginLeft: -37,
              fontFamily: font.family.sans.bold.value,
              fontSize: font.size.heading.two.value,
              lineHeight: font['line-height'].heading.two.value,
              color: font.color.heading.dark.value,
            }}
          />
        ),
        h3: ({children}) => (
          <p
            children={children}
            style={{
              marginLeft: -37,
              fontFamily: font.family.sans.bold.value,
              fontSize: font.size.heading.three.value,
              lineHeight: font['line-height'].heading.three.value,
              color: font.color.heading.dark.value,
            }}
          />
        ),
        h4: ({children}) => (
          <p
            children={children}
            style={{
              marginLeft: -37,
              fontFamily: font.family.sans.bold.value,
              fontSize: font.size.heading.four.value,
              lineHeight: font['line-height'].heading.four.value,
              color: font.color.heading.dark.value,
            }}
          />
        ),
        h5: ({children}) => (
          <p
            children={children}
            style={{
              marginLeft: -37,
              fontFamily: font.family.sans.bold.value,
              fontSize: font.size.heading.five.value,
              lineHeight: font['line-height'].heading.five.value,
              color: font.color.heading.dark.value,
            }}
          />
        ),
        h6: ({children}) => (
          <p
            children={children}
            style={{
              marginLeft: -37,
              fontFamily: font.family.sans.bold.value,
              fontSize: font.size.heading.six.value,
              lineHeight: font['line-height'].heading.six.value,
              color: font.color.heading.dark.value,
            }}
          />
        ),
        p: ({children}) => <Typography.Paragraph children={children} />,
        strong: ({children}) => <span children={children} className='tds-text__paragraph--bold' />,
      }}
    />
  ) as unknown as string;
