// See usage about runtime-env-cra: https://github.com/kHRISl33t/runtime-env-cra#usage
declare global {
  interface Window {
    __RUNTIME_CONFIG__: {
      NODE_ENV: string;
      ENVIRONMENT: string;
      LOG_PREFIX: string;
      LOG_LEVEL_ENDPOINT: string;
      LOG_LEVEL_LOCAL: string;
      LOG_ENDPOINT_PATH: string;
      LOG_USE_ENDPOINT: string;
      BASE_API_URI: string;
      MSAL_CLIENTID: string;
      MSAL_AUTHORITY: string;
      MSAL_AUTHORITYDOMAIN: string;
      MSAL_RESOURCES_SCOPE: string;
      MSAL_ADFS_RESOURCES_SCOPE: string;
      MSAL_ADFS_CLIENTID: string;
      MSAL_ADFS_AUTHORITY: string;
      MSAL_ADFS_ENVIRONMENT: string;
      BASE_CDN_URI: string;
    };
  }
}

// Redefine runtime config variable for easier access
const config = window.__RUNTIME_CONFIG__;

/**
 * Validates a given value with a validation predicate and returns the coerced value if the given string is valid.
 *
 * @param key the value to validate
 * @param isValid the predicate to use to check if the value is valid. If omitted, returns the value coerced,
 * but without validation.
 * @param coerce the coercing function to use to convert the value to the expected type
 * @returns a validated value or throws an error if invalid
 */
const validate = <T>(
  key: keyof typeof config,
  isValid?: (value: string) => boolean,
  coerce?: (value: string) => T,
): T => {
  const value = config[key];
  if (!isValid) return value as unknown as T;
  if (!isValid(value)) {
    throw new Error(`Invalid environment variable ${key} with value '${value}'`);
  }
  return coerce ? coerce(value) : (value as unknown as T);
};

// Checks if a given value is included in array
const isValid = (arr: readonly unknown[]) => (value: unknown) => arr.includes(value);

// Config specific validation logic

// ENVIRONMENT
const validEnvironments = ['local', 'dev', 'test', 'stage', 'prod'] as const;
const isValidEnv = isValid(validEnvironments);
type EnvironmentType = (typeof validEnvironments)[number];

// NODE_ENV
const validBuildEnvironments = ['development', 'production'] as const;
const isValidBuildEnv = isValid(validBuildEnvironments);
type BuildEnvironmentType = (typeof validBuildEnvironments)[number];

// LEVELS
const validLevels = ['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR', 'SILENT'] as const;
const isValidLevel = isValid(validLevels);
type LevelsType = (typeof validLevels)[number];

// BOOLEAN
const validBool = ['true', 'false'] as const;
const isValidBool = isValid(validBool);
const coerceBool = (value: string) => value === 'true';

// STRING
const isValidString = (str: string | undefined) => typeof str === 'string' && Boolean(str);

/**
 * Runtime environment variables used for configuration in the app.
 */
export const envVars = {
  /**
   * The React build environment. Different than the deployment environment. Only used to distinguish local development.
   */
  NODE_ENV: validate<BuildEnvironmentType>('NODE_ENV', isValidBuildEnv),
  /**
   * The environment the app is currently deployed and running in.
   */
  ENVIRONMENT: validate<EnvironmentType>('ENVIRONMENT', isValidEnv),
  LOG_PREFIX: validate<string>('LOG_PREFIX', isValidString),
  LOG_LEVEL_LOCAL: validate<LevelsType>('LOG_LEVEL_LOCAL', isValidLevel),
  LOG_LEVEL_ENDPOINT: validate<LevelsType>('LOG_LEVEL_ENDPOINT', isValidLevel),
  LOG_USE_ENDPOINT: validate<boolean>('LOG_USE_ENDPOINT', isValidBool, coerceBool),
  LOG_ENDPOINT_PATH: validate<string | undefined>('LOG_ENDPOINT_PATH'),
  BASE_API_URI: validate<string>('BASE_API_URI', isValidString),
  MSAL_CLIENTID: validate<string>('MSAL_CLIENTID', isValidString),
  MSAL_AUTHORITY: validate<string>('MSAL_AUTHORITY', isValidString),
  MSAL_AUTHORITYDOMAIN: validate<string>('MSAL_AUTHORITYDOMAIN', isValidString),
  MSAL_RESOURCES_SCOPE: validate<string>('MSAL_RESOURCES_SCOPE', isValidString),
  MSAL_ADFS_RESOURCES_SCOPE: validate<string>('MSAL_ADFS_RESOURCES_SCOPE', isValidString),
  MSAL_ADFS_CLIENTID: validate<string>('MSAL_ADFS_CLIENTID', isValidString),
  MSAL_ADFS_AUTHORITY: validate<string>('MSAL_ADFS_AUTHORITY', isValidString),
  MSAL_ADFS_ENVIRONMENT: validate<string>('MSAL_ADFS_ENVIRONMENT', isValidString),
  BASE_CDN_URI: validate<string>('BASE_CDN_URI', isValidString),
};
