import log from 'loglevel';
import {envVars} from './envVars';
import {sampleApi} from '@api';

const {LOG_PREFIX, LOG_LEVEL_LOCAL, LOG_USE_ENDPOINT, LOG_LEVEL_ENDPOINT} = envVars;

log.setLevel(LOG_LEVEL_LOCAL);

/**
 * To hook into the log() method, we have to derive our own method factory.
 * Pulled from loglevel documentation: https://github.com/pimterry/loglevel#writing-plugins
 */
const originalFactory = log.methodFactory;
log.methodFactory = function (methodName, logLevel, loggerName) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);

  return function (...messages: unknown[]) {
    const timestamp = new Date().toISOString();
    const prefix = LOG_PREFIX ? `${LOG_PREFIX} ` : '';
    const formatedMessage = `${prefix}${timestamp}\n${JSON.stringify(messages, null, 2)}`;
    // If logging to endpoint, and log level is great enough, send to endpoint
    if (LOG_USE_ENDPOINT && logLevel >= log.levels[LOG_LEVEL_ENDPOINT]) {
      // If network error, stop propagation
      sampleApi.sendLog(formatedMessage, logLevel).catch(() => Promise.resolve(null));
    }
    // Call the base logging method
    rawMethod(...messages);
  };
};
log.setLevel(log.getLevel()); // Be sure to call setLevel method in order to apply plugin

export {log};
