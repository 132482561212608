export function setFocus(
  element: HTMLElement,
  focusSelf: boolean,
  includeTabIndex: boolean,
  onlyIncludeVisible: boolean,
) {
  // If no element passed in, early return
  if (!element) return;

  // If focus self is set, focus the current element
  if (focusSelf) {
    element.focus();
    return;
  }

  // Else find focusable elements in element provided
  const focusElement = getFocusableElement(element, includeTabIndex, onlyIncludeVisible);

  // Focus any element found
  if (focusElement) {
    focusElement.focus();
    return;
  }

  // If not children were found, just focus self by default
  element.focus();
}

export function getFocusableElement(
  element: HTMLElement,
  includeTabIndex: boolean,
  onlyIncludeVisible: boolean,
) {
  let tabbableList = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'button:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'iframe',
    'object',
    'embed',
    '*[contenteditable=true]',
  ];

  if (includeTabIndex) {
    tabbableList.push('*[tabindex]');
  }

  if (onlyIncludeVisible) {
    tabbableList = tabbableList.map(sel => sel + ':visible');
  }

  const tababbleSelector = tabbableList.join(', ');

  return element.querySelector(tababbleSelector) as HTMLElement;
}
