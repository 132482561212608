/**
 * A function that returns a callback associated with a context that throws an error if called.
 * This can be used as a *default function value* when initializing context with the `createContext` call
 * ensuring `Context.Providers` wraps the component using the Context.
 *
 * @param contextName the name of the context to use in the error messsage
 * @returns throws an Error if this function is called indicating the context was not initialized before use
 */
export const notInitialized = (contextName: string) => () => {
  throw new Error(`${contextName} is not initialized`);
};
