import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {AuthConfig, createMsalConfig} from './createMsalConfig';
import {envVars} from './envVars';
import {AuthSettings} from '@types';

const config: AuthConfig = {
  clientId: envVars.MSAL_ADFS_CLIENTID,
  authority: envVars.MSAL_ADFS_AUTHORITY,
  knownAuthorities: [envVars.MSAL_ADFS_AUTHORITY],
  navigateToLoginRequestUrl: false,
};

const scope = [envVars.MSAL_ADFS_RESOURCES_SCOPE];
const msalInstance = new PublicClientApplication(createMsalConfig(config));
const accessTokenRequest = {
  scopes: scope,
  account: msalInstance.getAllAccounts()[0],
};

const exceptionHandler = <T>(exception: T): Promise<AuthenticationResult> =>
  Promise.reject(exception);
const success = (response: AuthenticationResult) => response;

const getAccessToken = async (): Promise<AuthenticationResult> =>
  await msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then(success)
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
      return exceptionHandler(error);
    });

export const adfsSettings: AuthSettings = {msalInstance, scope, getAccessToken};

export const useIsADFSAuthenticated = () => {
  const msalContext = useMsal();
  const isAuthenticated = useIsAuthenticated();
  return (
    isAuthenticated &&
    Boolean(
      msalContext?.accounts?.filter(
        account => account.environment === envVars.MSAL_ADFS_ENVIRONMENT,
      )?.length,
    )
  );
};
