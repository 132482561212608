import {ChildrenProps} from '@types';

/**
 * Creates a HOC (Higher Order Component) wrapper function that wraps other provided components
 * with the given wrapper component. Useful for creating `withContext` wrappers.
 *
 * @param WrapperComponent component to use for wrapping (typically a provider)
 * @param wrapperName name of the wrapped component for better debugability
 * @returns a function that simply wraps components given to it with the wrapper component
 */
export const makeHoc =
  (WrapperComponent: React.ComponentType<ChildrenProps>, wrapperName: string) =>
  <T,>(Component: React.ComponentType<T>) => {
    const ComponentWithBroker: React.FC<T> = props => (
      <WrapperComponent>
        <Component {...props} />
      </WrapperComponent>
    );
    ComponentWithBroker.displayName = `${wrapperName}(${
      (Component.displayName ?? Component.name) || 'Component'
    })`;
    return ComponentWithBroker;
  };
