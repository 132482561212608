import MockAdapter from 'axios-mock-adapter';
import {createClient} from './clients';
import {prepareApiObject} from './http';

const axios = createClient();

const mock = new MockAdapter(axios, {
  delayResponse: 100,
  onNoMatch: 'passthrough',
});

export type UserType = {id: number; name: string};
const users = [
  {
    id: 1,
    name: 'john smith',
  },
];
let currentUserID = 2;

// Example get request endpoint
mock.onGet('/users').reply(() => [200, users]);

// Example post request endpoint
mock.onPost('/users/create').reply(config => {
  const data = JSON.parse(config.data);
  users.push(data);
  return [200];
});

export const getRandomUser = () => async () => {
  const response = await mockApi.generateUser();
  const [{name}] = response.data.results;
  return {id: currentUserID++, name: `${name.first} ${name.last}`};
};

/**
 * Mock HTTP API methods used for state management demonstration
 */
const mockApi = {
  generateUser: () =>
    axios.get<{results: {name: {first: string; last: string}}[]}>('https://randomuser.me/api'),
  getUsers: () => axios.get<UserType[]>('/users'),
  createUser: (user: UserType) => axios.post('/users/create', user),
} as const;

prepareApiObject(mockApi);

export {mockApi};
