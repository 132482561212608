import {IInputFieldDto, InputType} from '@optum-uhone-sales/signatures-api-client/Signatures';

export const validateField = (
  field: IInputFieldDto,
  displayType?: 'Textarea' | 'Phone' | 'Currency',
) => {
  if (
    field.fieldValue &&
    (field.type === InputType.Date || displayType === 'Phone') &&
    field.fieldValue.length !== 10
  ) {
    return false;
  }

  if (field.isRequired) {
    return !!field.fieldValue;
  }

  return true;
};
