import {
  AuthenticationResult,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import {adfsSettings} from './authADFS';
import {b2cSettings} from './authB2C';
import {toCamelCase} from './utils';

export enum AuthType {
  B2C,
  ADFS,
}

export let currentAuthType = AuthType.B2C;

export function getMsalInstance(authType: AuthType): PublicClientApplication {
  currentAuthType = authType;
  switch (authType) {
    case AuthType.ADFS:
      return adfsSettings.msalInstance;
    case AuthType.B2C:
    default:
      return b2cSettings.msalInstance;
  }
}

export function getAccessToken(): Promise<AuthenticationResult> {
  switch (currentAuthType) {
    case AuthType.ADFS:
      return adfsSettings.getAccessToken();
    case AuthType.B2C:
    default:
      return b2cSettings.getAccessToken();
  }
}

export const extractCustomClaims = (
  instance: IPublicClientApplication,
  paramsToParse: Array<string>,
) => {
  const o = instance.getActiveAccount()?.idTokenClaims;
  if (o) {
    const customClaims = Reflect.get(o, 'customClaims') as string;
    const customClaimsArray = JSON.parse(customClaims, toCamelCase) as Array<
      Record<string, unknown>
    >;
    return customClaimsArray
      .filter(claim => paramsToParse.some(param => param === claim['claimName']))
      .map(claim => claim['claimValue'] as string);
  }
  return [];
};

export const getIdTokenClaims = (instance: IPublicClientApplication) => {
  const customClaims = instance.getActiveAccount();
  if (customClaims && customClaims.idTokenClaims) {
    return customClaims.idTokenClaims as {[key: string]: string};
  }

  return null;
};
