import type {ReportHandler} from 'web-vitals';

/**
 * Initialization that happens *before* React renders.
 */
export const initialize = async () => {};

/**
 * Used to report web vital metrics when the page loads.
 */
export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({getCLS, getFCP, getFID, getLCP, getTTFB}) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};
