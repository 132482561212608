/* eslint-disable @typescript-eslint/no-explicit-any */
import {AxiosResponse} from 'axios';
import log from 'loglevel';

export type GenericEndpoint<Return> = (...args: any[]) => Promise<AxiosResponse<Return> | null>;
export type GenericApiObject<Key extends string = string> = Record<Key, GenericEndpoint<unknown>>;

/**
 * Axios error handler that logs the error and stack trace of the calling code.
 *
 * @param error the error thrown by axios
 * @param trace the stack trace of the calling code
 * @returns a promise that resolves to the error
 */
const handleError = (error: any, trace?: string) => {
  if (error && typeof error === 'object') {
    // replace the async stack trace with stack trace used when request was made
    error.stack = trace;
  }
  log.error(error);
  return Promise.resolve(error);
};

/**
 * Sets the `name` property on each of the anonymous functions given by the object.
 * These function names can be used for query keys.
 *
 * @param obj the API object whose functions needs names
 * @returns the same object with the names set on each anonymous function
 */
const setMethodNames = (obj: GenericApiObject) =>
  Object.entries(obj).forEach(([key, value]) => Object.defineProperty(value, 'name', {value: key}));

/**
 * Wraps an async axios request to handle errors with a stack trace of the calling code for
 * clear traceability (because you can't trace an error effectively with pure callbacks due to
 * how JavaScripts handles the event loop).
 *
 * @param func the api method to wrap
 * @param hasHandleError whether or not the error handler should be invoked (default: true)
 * @returns the api method wrapped with error handling
 */
function withTrace<D, T extends GenericEndpoint<D>>(
  func: T,
  hasHandleError = true,
): (...funcArgs: Parameters<T>) => ReturnType<T> {
  return (...args: Parameters<T>): ReturnType<T> => {
    let trace = new Error().stack!;
    trace = trace.replace(/\n/g, ' \n');
    return func(...args).catch(e =>
      hasHandleError ? handleError(e, trace) : null,
    ) as ReturnType<T>;
  };
}

/**
 * Wraps an Api object's methods to handle errors properly with error handling and logging.
 *
 * @param obj the api object that has the functions that need to wrapped
 * @param ignore the functions (keys) of the api object that should not be wrapped (ignored)
 */
const wrapWithTrace = <T extends string>(obj: GenericApiObject<T>, ignore: T[] = []) => {
  Object.entries<GenericEndpoint<unknown>>(obj).forEach(
    ([key, value]) => (obj[key as T] = ignore.includes(key as T) ? value : withTrace(value)),
  );
};

/**
 * Prepares an api object (by mutation) before exporting for use.
 * Sets the names on the anonymous function and wraps trace error handling around the endpoint.
 * Finally freezes the object to prevent further changes.
 *
 * @param obj the api object to prepare
 * @param ignoreKeys the functions (keys) of the api object that should not be wrapped (ignored)
 */
export const prepareApiObject = <T extends string>(
  obj: GenericApiObject<T>,
  ignoreKeys: T[] = [],
): void => {
  wrapWithTrace(obj, ignoreKeys);
  setMethodNames(obj);
  Object.freeze(obj);
};
